.Burger {
  widows: 100%;
  margin: auto;
  height: 250px;
  overflow: scroll;
  scrollbar-width: none;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
}

@media (min-width: 500px) and (max-height: 400px) {
  .Burger {
    width: 450px;
    height: 300px;
  }
}

@media (min-width: 500px) and (min-height: 401px) {
  .Burger {
    width: 450px;
    height: 300px;
  }
}

@media (min-width: 1000px) and (min-height: 700px) {
  .Burger {
    width: 700px;
    height: 600px;
  }
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
